<template>
  <div class="Profile">
    <user-block class="mb-5" />

    <!--
    <div class="row">
      <div class="col-md-6">
        <sign-in-method />
      </div>
      <div class="col-md-6">
        <change-password />
      </div>
    </div>-->
  </div>
</template>

<script>
import UserBlock from './ProfileUser/ProfileUser.vue'
// import SignInMethod from './ProfileSignInMethod.vue'
// import ChangePassword from './ProfileChangePassword.vue'

export default {
  components: {
    UserBlock
    // SignInMethod,
    // ChangePassword
  }
}
</script>

<style lang="scss" scoped>
  .Profile {

  }
</style>
