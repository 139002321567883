<template>
  <div class="ProfileUser">
    <!-- <div class="top">
      User Information
    </div> -->
    <div class="card mb-4" v-if="user.subscription_status === 'past_due'">
      <div class="card-body-2" style="background: #E3EAEC;">
        <h3 style="margin-top: 0">Incomplete payment</h3>
        <p>Your payment could not be completed due to one or more issues, such as an incomplete 3D Secure confirmation, insufficient funds, or other payment errors. Please check your credit card balance and try again, or consider using a different payment method if needed.
          <br>
          <br>
          This message will automatically disappear once the payment is successful.</p>
        <base-button class="mt-2" @click="retryPayment">Try again</base-button>
      </div>
    </div>
    <div class="card">
      <div class="card-body">
        <div class="avatar-with-info">
          <div class="avatar">
            <img :src="require('@/assets/images/account.svg')" width="90" />
          </div>
          <div class="info ml-4">
            <div class="mb-1" v-if="user.name">
              {{ user.name }}
            </div>
            <div class="mb-1">
              {{ user.email }}
            </div>
            <div class="mb-1" v-if="user.phone_number">
              {{ user.phone_number }}
            </div>
            <div class="mb-1" v-if="user.role">
              Role: {{ user.role }}
            </div>
            <div class="member-since">
              Member since: {{ user.created_at | memberSince }}
            </div>
          </div>
        </div>
        <div class="right">
          <base-button :outlined="true"
                       class="account-button"
                       @click="openEditProfileModal">Edit Profile</base-button>
          <base-button v-if="this.user.role === role.Admin" :outlined="true"
                       class="account-button"
                       @click="openEditPaymentMethodModal()">Edit Card Details</base-button>
          <base-button :outlined="true" class="account-button"
                        @click="exportUserData()" :loading="exportLoading">Export your data</base-button>
        </div>
      </div>
    </div>

    <base-alert type="error" class="mt-4" v-if="exportError">Something went wrong</base-alert>

    <v-modal name="editProfile" height="auto" id="editProfileModal">
      <edit-profile @update="onProfileUpdate" :countries="countries"
                    @close="$modal.hide('editProfile')" />
    </v-modal>

    <v-modal name="editPaymentMethod" height="auto" id="editPaymentMethodModal">
        <card-details-edit :user="user" @close="$modal.hide('editPaymentMethod')" @card-update="onCardUpdate"/>
    </v-modal>
  </div>
</template>

<script>
import EditProfile from './ProfileUserEdit.vue'
import getUserLocale from 'get-user-locale'
import profileApi from '@/api/profileApi.js'
import { Role } from '@/_helpers/role'
import BaseAlert from '../../../Base/BaseAlert'
import Swal from 'sweetalert2'
import countries2 from '@amcharts/amcharts4-geodata/data/countries2'
import CardDetailsEdit from './CardDetailsEdit'

const date = new Intl.DateTimeFormat(getUserLocale(), {
  year: 'numeric',
  month: 'long',
  day: '2-digit'
})

export default {
  components: {
    CardDetailsEdit,
    BaseAlert,
    EditProfile
  },

  data () {
    return {
      role: Role,
      exportLoading: false,
      exportError: false,
      countries: [],
      retryPaymentError: 'Something went wrong'
    }
  },

  mounted () {
    for (const [key, value] of Object.entries(countries2)) {
      this.countries.push({ type: key, title: value.country })
    }

    this.refreshCurrentUser('tax')
  },

  methods: {
    openEditProfileModal () {
      this.$modal.show('editProfile')
    },

    openEditPaymentMethodModal () {
      this.$modal.show('editPaymentMethod')
    },

    onProfileUpdate () {
      this.$modal.hide('editProfile')
      this.refreshCurrentUser('tax')
    },

    onCardUpdate () {
      this.$modal.hide('editPaymentMethod')
      this.refreshCurrentUser('tax')
    },

    async exportUserData () {
      this.exportError = false
      this.exportLoading = true
      try {
        await profileApi.exportUserData()
        this.showSuccessMessage()
      } catch (error) {
        this.exportError = true
      }
      this.exportLoading = false
    },

    showSuccessMessage () {
      Swal.fire({
        title: 'Success',
        text: 'An email with your download link will be sent to ' + this.user.email,
        showClass: {
          popup: ''
        },
        hideClass: {
          popup: ''
        },
        confirmButtonColor: '#6652e0',
        allowOutsideClick: true
      })
    },

    ...mapActions('authentication', [
      'refreshCurrentUser'
    ]),

    async retryPayment () {
      try {
        const response = await profileApi.retryPayment()
        this.showPaySuccessMessage(response)
      } catch (error) {
        if (error.response.data && error.response.data.message) {
          this.retryPaymentError = error.response.data.message
        }
        this.showPayErrorMessage()
      }
    },

    showPayErrorMessage () {
      Swal.fire({
        title: 'Oops',
        text: this.retryPaymentError,
        showClass: {
          popup: ''
        },
        hideClass: {
          popup: ''
        },
        allowOutsideClick: true
      })
    },

    showPaySuccessMessage (response) {
      Swal.fire({
        title: 'Success',
        text: response,
        showClass: {
          popup: ''
        },
        hideClass: {
          popup: ''
        },
        confirmButtonColor: '#6652e0',
        allowOutsideClick: true
      })
    }
  },

  filters: {
    memberSince (timestamp) {
      return date.format(new Date(timestamp * 1000))
    }
  },

  computed: {
    ...mapState('authentication', [
      'user'
    ])
  }
}
</script>

<style lang="scss" scoped>
  .ProfileUser {
    .top {
      margin-bottom: 10px;
      font-size: 24px;
      font-weight: 400;
    }

    .card-body {
      display: flex;
      align-items: center;
      justify-content: space-between;
      @media (max-width: 767px) {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
      }
    }

    .card-body-2 {
      -ms-flex: 1 1 auto;
      flex: 1 1 auto;
      min-height: 1px;
      padding: 1.25rem;
    }

    .right {
      @media (max-width: 767px) {
        margin-top: 24px;
        width: 100%;
      }
    }

    .avatar-with-info {
      display: flex;
      //align-items: center;
      @media (max-width: 450px) {
        flex-direction: column;
        width: 100%;
      }
    }

    .info {
      color: #777777;
      @media (max-width: 450px) {
        margin-left: 0 !important;
        margin-top: 15px;
        width: 100%;
      }
    }

    .account-button {
      width: 154px;
      margin-bottom: 10px;
      @media (max-width: 767px) {
        width: 100%;
      }
    }

    #editProfileModal {
      overflow-y: scroll;
      padding: 16px 0;
    }
  }
</style>
