<template>
  <div class="Profile">
    <account-profile />
  </div>
</template>

<script>
import AccountProfile from '@/components/Account/Profile/Profile.vue'

export default {
  components: {
    AccountProfile
  },

  metaInfo: {
    title: 'Profile'
  }
}
</script>
